import { lazy } from 'react'
import locales from './locales'
import routes from './routes'
import themes from './themes'
import parseLanguages from 'base-shell/lib/utils/locale'
import grants from './grants'
import Loading from 'material-ui-shell/lib/components/Loading/Loading'
import {
  defaultUserData,
  isGranted,
  isAnyGranted,
} from 'rmw-shell/lib/utils/auth'
import { getDefaultRoutes } from './getDefaultRoutes'

const config = {
  firebase: {
    prod: {
      initConfig: {
        apiKey: "AIzaSyCErKBMDtcmc09Mrr6bjNlTy0iKH5w5E54",
        authDomain: "sensibly-prod.firebaseapp.com",
        databaseURL: "https://sensibly-prod-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "sensibly-prod",
        storageBucket: "sensibly-prod.appspot.com",
        messagingSenderId: "940336049467",
        appId: "1:940336049467:web:dc8d896367ab75cdcb5b1e",
        measurementId: "G-HJNP3VSMXC"
      },
      messaging: {
        publicVapidKey:
          'BPrck0_sfICMg0jue7BGejwb8GpYPm3NsHxTg_SFLwhASQ8wzJzSgUC_xQjbq5vdfom-FzgILRRG8e0JhP68TvI',
      },
    },
    devp: {
      initConfig: {
        apiKey: "AIzaSyBUhlxQ8cqd7wprqd7OMefF1V94EVPO3Zc",
        authDomain: "sensibly-dev.firebaseapp.com",
        databaseURL: "https://sensibly-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "sensibly-dev",
        storageBucket: "sensibly-dev.appspot.com",
        messagingSenderId: "138510216558",
        appId: "1:138510216558:web:87a6cc70a159b4ee50e57f"
      },
      messaging: {
        publicVapidKey:
          'BKKZYKMJ7Yg3WSVQMubc0i_zTeX9vXNP122gThy4Y5Qs_aDjFOtYGsP_BicLCOBJwvfy6v61lrLD-iM6i_AixGs',
      },
    },
    dev: {
      initConfig: {
        apiKey: "AIzaSyBUhlxQ8cqd7wprqd7OMefF1V94EVPO3Zc",
        authDomain: "sensibly-dev.firebaseapp.com",
        databaseURL: "https://sensibly-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "sensibly-dev",
        storageBucket: "sensibly-dev.appspot.com",
        messagingSenderId: "138510216558",
        appId: "1:138510216558:web:87a6cc70a159b4ee50e57f"
      },
      messaging: {
        publicVapidKey:
          'BKKZYKMJ7Yg3WSVQMubc0i_zTeX9vXNP122gThy4Y5Qs_aDjFOtYGsP_BicLCOBJwvfy6v61lrLD-iM6i_AixGs',
      },
    },
    firebaseuiProps: {
      signInOptions: [
        'google.com',
      ],
    },
  },
  googleMaps: {
    apiKey: 'AIzaSyByMSTTLt1Mf_4K1J9necAbw2NPDu2WD7g',
  },
  auth: {
    grants,
    redirectTo: '/dashboard',
    persistKey: 'base-shell:auth',
    signInURL: '/signin',
    onAuthStateChanged: async (user, auth) => {
      const { getDatabase, ref, onValue, get, update, off } = await import(
        'firebase/database'
      )
      const db = getDatabase()

      try {
        if (user != null) {
          const grantsSnap = await get(ref(db, `user_grants/${user.uid}`))
          const notifcationsDisabledSnap = await get(
            ref(db, `disable_notifications/${user.uid}`)
          )

          const isAdminSnap = await get(ref(db, `admins/${user.uid}`))

          onValue(ref(db, `user_grants/${user.uid}`), (snap) => {
            auth.updateAuth({ grants: snap.val() })
          })
          onValue(ref(db, `disable_notifications/${user.uid}`), (snap) => {
            auth.updateAuth({ notificationsDisabled: !!snap.val() })
          })
          onValue(ref(db, `admins/${user.uid}`), (snap) => {
            auth.updateAuth({ isAdmin: !!snap.val() })
          })

          auth.updateAuth({
            ...defaultUserData(user),
            grants: grantsSnap.val(),
            notificationsDisabled: notifcationsDisabledSnap.val(),
            isAdmin: !!isAdminSnap.val(),
            isGranted,
            isAnyGranted,
          })

          update(ref(db, `users/${user.uid}`), {
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user.photoURL,
            providers: user.providerData,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            notificationsDisabled: notifcationsDisabledSnap.val(),
          })

          update(ref(db, `user_chats/${user.uid}/public_chat`), {
            displayName: 'Public Chat',
            lastMessage: 'Group chat',
            path: `group_chat_messages/public_chat`,
          })
        } else {
          off(ref(db))

          auth.setAuth(defaultUserData(user))
        }
      } catch (error) {
        console.warn(error)
      }
    },
  },
  getDefaultRoutes: getDefaultRoutes,
  routes,
  locale: {
    locales,
    persistKey: 'base-shell:locale',
    defaultLocale: parseLanguages(['en', 'de', 'ru'], 'en'),
    onError: (e) => {
      //console.warn(e)

      return
    },
  },
  menu: {
    MenuContent: lazy(() => import('../components/Menu/MenuContent')),
    MenuHeader: lazy(() =>
      import('../components/Menu/MenuHeader')
    ),
  },
  theme: {
    themes,
    defaultThemeID: 'default',
    defaultType: 'light',
  },
  pages: {
    LandingPage: lazy(() => import('../pages/LandingPage')),
    PageNotFound: lazy(() => import('../pages/PageNotFound')),
  },
  components: {
    Menu: lazy(() => import('material-ui-shell/lib/containers/Menu/Menu')),
    Loading,
  },

  containers: {
    LayoutContainer: lazy(() =>
      import('rmw-shell/lib/containers/LayoutContainer/LayoutContainer')
    ),
  },
}

export default config
