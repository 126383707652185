/* eslint-disable react/jsx-key */
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute'
import React, { lazy } from 'react'

const About = lazy(() => import('../pages/About'))
const Admin = lazy(() => import('../pages/Demo/Admin'))
const Companies = lazy(() => import('../pages/Demo/Companies'))
const Company = lazy(() => import('../pages/Demo/Companies/Company'))
const Tasks = lazy(() => import('../pages/Demo/Tasks'))
const Task = lazy(() => import('../pages/Demo/Tasks/Task'))
const FirebaseCols = lazy(() => import('../pages/Firebase/Cols'))
const FirebaseDocs = lazy(() => import('../pages/Firebase/Docs'))
const FirebaseLists = lazy(() => import('../pages/Firebase/Lists'))
const FirebaseMessaging = lazy(() => import('../pages/Firebase/Messaging'))
const FirebasePaths = lazy(() => import('../pages/Firebase/Paths'))
const FirebaseStorage = lazy(() => import('../pages/Firebase/Storage'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
const Clients = lazy(() => import('../pages/Clients'))
const Client = lazy(() => import('../pages/Clients/Client'))
const Events = lazy(() => import('../pages/Events'))
const Notes = lazy(() => import('../pages/Notes'))
const AddNote = lazy(() => import('../pages/Notes/Add'))
const NoteTypes = lazy(() => import('../pages/Admin/NoteTypes'))
const AddNoteType = lazy(() => import('../pages/Admin/AddNoteType'))
const Revenue = lazy(() => import('../pages/Revenue'))
const RevenueCharts = lazy(() => import('../pages/Revenue/Charts'))
const Agreements = lazy(() => import('../pages/Agreements'))
const FeeConsents = lazy(() => import('../pages/FeeConsents'))
const ClosedAgreements = lazy(() => import('../pages/Agreements/Closed'))
const ClosedConsents = lazy(() => import('../pages/Consents/Closed'))
const ExpiredAgreements = lazy(() => import('../pages/Agreements/Expired'))
const ExpiredConsents = lazy(() => import('../pages/Consents/Expired'))
const ExpiringAgreements = lazy(() => import('../pages/Agreements/Expiring'))
const ExpiringConsents = lazy(() => import('../pages/Consents/Expiring'))
const Xplan = lazy(() => import('../pages/Xplan'))
const Oauth2callback = lazy(() => import('../pages/Oauth2callback'))


const routes = [
  {
    path: '/about',
    exact: true,
    element: <About />,
  },
  {
    path: '/dashboard',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/events',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Events />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/notes',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Notes />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/notes/add',
    exact: true,
    element: (
      <AuthorizedRoute>
        <AddNote />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/clients',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Clients />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/client/:entity_id',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Client />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/revenue',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Revenue />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/revenue/charts',
    exact: true,
    element: (
      <AuthorizedRoute>
        <RevenueCharts />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/agreements',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Agreements />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/agreements/closed',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ClosedAgreements />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/agreements/expired',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ExpiredAgreements />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/agreements/expiring',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ExpiringAgreements />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/fee-consents',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FeeConsents />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/consents/closed',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ClosedConsents />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/consents/expired',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ExpiredConsents />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/consents/expiring',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ExpiringConsents />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_paths',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebasePaths />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_lists',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseLists />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_docs',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseDocs />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_cols',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseCols />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/admin',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Admin />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/admin/note-types',
    exact: true,
    element: (
      <AuthorizedRoute>
        <NoteTypes />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/admin/note-types/add',
    exact: true,
    element: (
      <AuthorizedRoute>
        <AddNoteType />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/companies',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Companies />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/companies/:uid',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Company />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/create_company',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Company />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/tasks',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Tasks />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/tasks/:uid',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Task />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/create_task',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Task />
      </AuthorizedRoute>
    ),
  },

  {
    path: '/firebase_messaging',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseMessaging />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/firebase_storage',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FirebaseStorage />
      </AuthorizedRoute>
    ),
  },  
  {
    path: '/xplan',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Xplan />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/oauth2callback',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Oauth2callback />
      </AuthorizedRoute>
    ),
  },
]

export default routes
