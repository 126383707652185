export const grants = [
  "create_company",
  "edit_company",
  "delete_company",
  "read_companies",
  "create_note",
  "edit_note",
  "delete_note",
  "read_notes",
  "create_note-type",
  "edit_note-type",
  "delete_note-type",
  "read_note-types",
];

export default grants;
